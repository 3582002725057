<template>
  <v-dialog v-model="dialog" max-width="600px" persistent @click:outside="close">
    <v-card v-if="item.type == 'load'" color="primary" class="pa-2">
      <v-card-text class="text-body-1 white--text">
        {{ item.loadText }}
        <br />
        <v-progress-linear indeterminate color="white"></v-progress-linear>
      </v-card-text>
    </v-card>

    <v-card v-else class="text-center">
      <v-toolbar v-if="item.type == 'error'" color="red" dark flat>
        <v-icon>{{ icons.alertCircleOutline }}</v-icon>
        <span class="text-h6 mx-1"> Error</span>
        <v-spacer></v-spacer>
        <v-icon v-if="item.close" @click="close">midi-close</v-icon>
      </v-toolbar>

      <v-toolbar v-else color="primary" dark flat>
        <v-icon>{{ icons.checkBold }}</v-icon>
        <span class="text-h6 mx-1"> Complete</span>
        <v-spacer></v-spacer>
        <v-icon v-if="item.close" @click="close">midi-close</v-icon>
      </v-toolbar>

      <v-card-text v-if="item.type == 'comp'" class="py-7 text--primary">
        {{ item.compText }}
      </v-card-text>
      <v-card-text v-if="item.type == 'error'" class="py-7 text--primary">
        {{ item.errorText }}
      </v-card-text>
      <v-btn v-if="item.toTop & (item.type == 'comp')" link to="/" outlined color="green" class="my-1 mx-auto"
        @click="close">トップページ</v-btn>
      <v-btn v-if="item.reload" outlined color="red" class="my-1 mx-auto" @click="reload">リロード</v-btn>
    </v-card>
  </v-dialog>
</template>

<script>
import { mdiAlertCircleOutline, mdiCheckBold } from "@mdi/js";

export default {
  name: "DialogStatus",
  props: ["item"],

  data() {
    return {
      icons: {
        alertCircleOutline: mdiAlertCircleOutline,
        checkBold: mdiCheckBold,
      },
      dialog: false,
    };
  },

  watch: {
    item: {
      handler(newVal) {
        if (newVal.type) this.dialog = true;
        else this.dialog = false;
      },
      deep: true,
    },
  },

  methods: {
    close() {
      this.dialog = false;
      this.$emit("closeEmit");
    },
    reload() {
      this.dialog = false;
      this.$emit("closeEmit");
      this.$router.go({ path: this.$router.currentRoute.path, force: true });
    },
  },
};
</script>
